import React from "react"
import { StyleProp, View, ViewStyle, Linking } from "react-native"
import { observer } from "mobx-react-lite"
import { useNavigation } from "@react-navigation/native"
import { spacing } from "@app/theme"
import { Button, LocationButton, Text, useModal } from "@app/components"
import { flatten } from "ramda"
import { TabsScreenProps } from "@app/navigators"
import { RootNavigatorParamList } from "@app/navigators/navigator-params"
import { Feather } from "@expo/vector-icons"
import { textPresets } from "@app/components/button/button.presets"
import { useStores } from "@app/models"

interface ModalContentNavigationProps {
  style?: StyleProp<ViewStyle>
}

export const ModalContentNavigation = observer((props: ModalContentNavigationProps) => {
  const { style } = props
  const styles = flatten([CONTAINER, style])
  const navigation = useNavigation<TabsScreenProps["navigation"]>()
  const { closeModal } = useModal()
  const { user, logout } = useStores()
  const closeAndNavigateTo = (route: keyof RootNavigatorParamList) => {
    closeModal()
    navigation.navigate(route)
  }

  return (
    <View style={styles}>
      <View style={TOP}>
        <LocationButton style={LOCATION_BUTTON} />
        <Button preset="icon" onPress={closeModal} style={CLOSE_BUTTON}>
          <Feather style={textPresets.icon} name="x" />
        </Button>
      </View>
      <View style={LINKS}>
        <Button preset="menu" onPress={() => closeAndNavigateTo("About")}>
          <Feather style={textPresets.menuIcon} name="info" />
          <Text style={textPresets.menu} text="Om oss" />
        </Button>
        <Button preset="menu" onPress={() => closeAndNavigateTo("Faq")}>
          <Feather style={textPresets.menuIcon} name="help-circle" />
          <Text style={textPresets.menu} text="Frågor & svar" />
        </Button>
        <Button preset="menu" onPress={() => closeAndNavigateTo("Contact")}>
          <Feather style={textPresets.menuIcon} name="message-square" />
          <Text style={textPresets.menu} text="Kontakta oss" />
        </Button>
        <Button
          preset="menu"
          onPress={() =>
            Linking.openURL(
              "https://veckansr.se/"
              // "https://docs.google.com/forms/d/e/1FAIpQLSfv-BkN1mTPibfCCsFUBBaWeeDH87ntdbGFLPEZnVjnrqOdfg/viewform?usp=sf_link"
            )
          }
        >
          <Feather style={textPresets.menuIcon} name="tag" />
          <Text style={textPresets.menu} text="Rabatter" />
        </Button>
        <Button preset="menu" onPress={() => closeAndNavigateTo("Tabs")}>
          <Feather style={textPresets.menuIcon} name="home" />
          <Text style={textPresets.menu} text="Hem" />
        </Button>
        {!user ? (
          <Button preset="menu" onPress={() => closeAndNavigateTo("Login")}>
            <Feather style={textPresets.menuIcon} name="user" />
            <Text style={textPresets.menu} text="Logga in" />
          </Button>
        ) : (
          <Button preset="menu" onPress={logout}>
            <Feather style={textPresets.menuIcon} name="user" />
            <Text style={textPresets.menu} text="Logga ut" />
          </Button>
        )}

        <View style={DIVIDER} />
        <Button preset="wide" onPress={() => closeAndNavigateTo("RegisterNewsletter")}>
          <Text style={textPresets.primary} text="Få nyhetsbrevet" />
        </Button>
        {/* <Button
          containerStyle={FILL_WIDTH}
          style={CENTER_BUTTON}
          preset="secondary"
          onPress={() => navigation.navigate("Tabs")}
        >
          <Text style={textPresets.secondary} text="Logga in" />
        </Button> */}
      </View>
    </View>
  )
})

const CONTAINER: ViewStyle = {
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: spacing[6],
  flex: 1
}

const LINKS: ViewStyle = {
  justifyContent: "flex-start",
  alignItems: "stretch",
  marginBottom: 0,
  width: "100%",
  flex: 1
}

const LOCATION_BUTTON: ViewStyle = {
  marginBottom: spacing[4]
}

const DIVIDER: ViewStyle = {
  marginBottom: 16
}

const CLOSE_BUTTON: ViewStyle = {
  alignSelf: "flex-end"
}

const TOP: ViewStyle = {
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%"
}
